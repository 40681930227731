$(function () {
  var $dropdowns = $('.js-dropdown');
  var $document = $(document);

  // Early exit
  if(!$dropdowns.length) return;

  var get_control = function ($dropdown) {
    var id = $dropdown.attr('id');
    return $dropdown.find('#' + id + "-dropdown-control");
  };

  var get_menu = function ($dropdown) {
    var id = $dropdown.attr('id');
    return $dropdown.find('#' + id + "-dropdown-menu");
  };

  var toggle = function ($dropdown) {
    var $control = get_control($dropdown);
    var state = $control.attr('aria-expanded') === "true";
    state ? hide($dropdown) : show($dropdown);
  };

  var show = function ($dropdown) {
    var $control = get_control($dropdown);
    var $menu = get_menu($dropdown);
    $control.attr('aria-expanded', "true");
    $menu.attr('aria-hidden', "false");
    $menu.focus();
  };

  var hide = function ($dropdown) {
    var $control = get_control($dropdown);
    var $menu = get_menu($dropdown);
    $control.attr('aria-expanded', "false");
    $menu.attr('aria-hidden', "true");
  };

  var mount = function ($dropdown) {
    var $control = get_control($dropdown);
    var $menu = get_menu($dropdown);
    $control.attr('aria-controls', $menu.attr('id'));
    $control.attr('aria-expanded', "false");
    $menu.attr('aria-hidden', "true");
    $menu.attr('tabindex', "-1");

    $control.on('click', function (event) {
      toggle($dropdown);
    });

    $document.on("click", function (event) {
      var target = event.target;

      do {
        if (target == $dropdown[0]) {
          return;
        }
        target = target.parentNode;
      } while (target);

      hide($dropdown);
    });
  };

  var init = function () {
    $dropdowns.each(function (index, dropdown) {
      var $dropdown = $(dropdown);
      mount($dropdown);
    });
  };

  init();
});
