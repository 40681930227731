$(function() {
  $panels = $('.js-panel');

  // Early exit
  if(!$panels.length) return;

  var $window = $(window);

  var unmount = function ($items) {
    $items.slick("destroy");

    return false;
  };

  var mount = function ($items) {
    $items.slick({
      dots: false,
      infinite: false,
      arrows: false,
      variableWidth: true,
      initialSlide: Math.round($items.find('.js-panel-item').length / 2) - 1,
      centerMode: true
    });

    return true;
  };

  $panels.each(function (index, panel) {
    var $panel = $(panel);
    var $items = $panel.find('.js-panel-carousel');
    var state = false;
    var has_resized = false;

    var update = function () {
      if(has_resized) {
        if($items.css("overflow") == "hidden" && state) { // Slider no need to be active
          state = unmount($items);
        } else if($items.css("overflow") != "hidden" && !state) { // Slider need to be active
          state = mount($items);
        }
      }

      has_resized = false;
      window.requestAnimationFrame(update);
    };

    $window.on('resize', function() {
      has_resized = true;
    });

    if($items.css("overflow") != "hidden") {
      state = mount($items);
    }

    window.requestAnimationFrame(update);
  });
});
