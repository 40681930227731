$(function () {
  var $accordions = $('.js-accordion');

  // Early exit
  if(!$accordions.length) return;

  var get_control = function ($accordion) {
    var id = $accordion.attr('id');
    return $accordion.find('#' + id + "-accordion-control");
  };

  var get_panel = function ($accordion) {
    var id = $accordion.attr('id');
    return $accordion.find('#' + id + "-accordion-panel");
  };

  var toggle = function ($accordion) {
    var $control = get_control($accordion);
    var state = $control.attr('aria-expanded') === "true";
    state ? hide($accordion) : show($accordion);
  };

  var show = function ($accordion) {
    var $control = get_control($accordion);
    var $panel = get_panel($accordion);
    hide_all();
    $control.attr('aria-expanded', "true");
    $panel.attr('aria-hidden', "false");
    $panel.focus();
  };

  var hide = function ($accordion) {
    var $control = get_control($accordion);
    var $panel = get_panel($accordion);
    $control.attr('aria-expanded', "false");
    $panel.attr('aria-hidden', "true");
  };

  var hide_all = function ($accordion) {
    $accordions.each(function (index, accordion) {
      hide($(accordion));
    });
  };

  var mount = function ($accordion) {
    var $control = get_control($accordion);
    var $panel = get_panel($accordion);
    $control.attr('aria-controls', $panel.attr('id'));
    $control.attr('aria-expanded', "false");
    $panel.attr('aria-hidden', "true");
    $panel.attr('tabindex', "-1");

    $control.on('click', function (event) {
      toggle($accordion);
    });
  };

  var init = function () {
    $accordions.each(function (index, accordion) {
      var $accordion = $(accordion);
      mount($accordion);
    });
  };

  init();
});
