$(function () {
  var $window = $(window);
  var $burgers = $('.js-burger');
  var $body = $('body');
  var body_width = $body.width();

  // Early exit
  if(!$burgers.length) return;

  $burgers.each(function (index, burger) {
    var $burger = $(burger);
    var $target = $('#' + $burger.attr('aria-controls'));
    var state = $burger.attr('aria-expanded');
    var is_mounted = false;

    var toggle_target = function () {
      state ? hide_target(true) : show_target();
    };

    var show_target = function () {
      $burger.attr('aria-expanded', "true");
      $target.attr('aria-hidden', "false");
      state = true;
      $target.focus();
      $target.trigger('toggled', { state: state });
    };

    var hide_target = function (toggled) {
      if(toggled) {
        $target.one('animationend', function (event) {
          $target.attr('aria-hidden', "true");
          $target.removeClass('is-hiding');
          state = false;
          $target.trigger('toggled', { state: state });
        });

        $burger.attr('aria-expanded', "false");
        $target.addClass('is-hiding');
      } else {
        $burger.attr('aria-expanded', "false");
        $target.attr('aria-hidden', "true");
        state = false;
        $target.trigger('toggled', { state: state });
      }
    };

    var update = function () {
      tmp = $body.width();

      if(is_mounted && $burger.css("display") == "none") {
        unmount();
      } else if(!is_mounted && $burger.css("display") != "none") {
        mount();
      } else if(is_mounted && $burger.css("display") != "none" && tmp != body_width) {
        body_width = tmp;
        hide_target();
      }
    };

    var init = function () {
      if($burger.css("display") != "none") {
        mount();
      }
    };

    var mount = function () {
      hide_target();
      is_mounted = true;
    };

    var unmount = function () {
      $burger.removeAttr('aria-expanded');
      $target.removeAttr('aria-hidden');
      is_mounted = false;
      $target.trigger('toggled', { state: false });
    };

    $burger.on('click', toggle_target);
    $target.on('update', update);
    $window.on('resize', $.debounce(update, 25));

    init();
  });
});
