$(function() {
  var $popins = $(".js-popin");
  var $body = $("body");

  // Early exit
  if(!$popins.length) return;

  var hide = function ($popin, trapper) {
    $popin.attr("aria-hidden", "true");
    trapper.release();
  };

  var show = function ($popin, trapper) {
    $popin.attr("aria-hidden", "false");
    $popin.focus();
    trapper.catch();
  };

  $popins.each(function(index, popin) {
    var $popin = $(popin);
    var id = $popin.attr('id');
    var $close = $popin.find('.js-popin-close');
    var $buttons = $('[href*="#' + id + '"]');
    var trapper = new FocusTrapper($popin);
    var trigger = false;
    var $popinCatalog = $("#popin-cadaoz-shop");

    $popin.attr('role', "dialog");

    $buttons.on("click", function (event) {
      event.preventDefault();
      show($popin, trapper);
      trigger = this;
    });

    $close.on("click", function (event) {
      hide($popin, trapper);
      $(trigger).focus();
      trigger = false;
    });

    hide($popin, trapper);
    if(localStorage.getItem('popState') != 'shown') {
      setTimeout(function(){
        show($popinCatalog, trapper);
      },2000);
      localStorage.setItem('popState','shown');
    }
  });
});
