$(function () {
  var $inputs_with_condition = $('[data-condition]');
  var input = {};
  var inputs = [];
  $inputs_with_condition.each(function () {
    var $input_parent = $(this).parent();
    var $previous_sibling = $input_parent.prev();
    input = {
      key: $(this).data('key'),
      parent: $input_parent,
      previous_sibling: $previous_sibling,
      conditions: $(this).data('condition')
    }
    inputs.push(input);
  });

  init(inputs);

  function init(inputs) {
    $.each(inputs, function(i, input) {
      input.parent.remove();
      var result_condition = null;
      var $field;
      var operator;
      var value;
      $.each(input.conditions, function() {
        $field = $('[data-key="' + this[0].field +'"]');
        operator = this[0].operator;
        value = this[0].value;
        result_condition = result_condition + checkConditions(operator, $field, value);
      });
      displayInput(result_condition, input.previous_sibling, input.parent);
      result_condition = null;
      $($field).change(function() {
        $.each(input.conditions, function() {
          $field = $('[data-key="' + this[0].field +'"]');
          operator = this[0].operator;
          value = this[0].value;
          result_condition = result_condition + checkConditions(operator, $field, value);
        });
        displayInput(result_condition, input.previous_sibling, input.parent);
        result_condition = null;
      });
    });
  }

  function checkConditions(operator, field, value) {
    var operators = {
      '==': function(a, b) { return a == b },
      '!=': function(a, b) { return a != b },
      '<': function(a, b) { return a < b },
      '>': function(a, b) { return a > b },
    };
    var field_value = field.val();
    return operators[operator](field_value, value);
  }

  function displayInput(result_condition, previous, input) {
    result_condition > 0 ? previous.after(input) : input.remove();
  }
});