$(function () {
  var $document = $(document);
  var $header = $('#header');
  var $navigation = $('#navigation');
  var $button = $header.find('.js-header-button');
  var $window = $(window);
  var header_height = $header.outerHeight();
  var is_sticky = false;
  var has_scroll = false;
  var is_deployed = false;
  var scroll = $document.scrollTop();

  var update = function () {
    if(has_scroll) {
      if(!is_deployed) {
        scroll = $document.scrollTop();

        if(scroll > header_height && !is_sticky) {
          $header.addClass('is-sticky');
          is_sticky = true;
          $navigation.trigger('update');
        } else if(scroll <= header_height / 2 && is_sticky) {
          $header.removeClass('is-sticky');
          is_sticky = false;
          $navigation.trigger('update');
        }
      }
    }
    has_scroll = false;
    window.requestAnimationFrame(update);
  };

  $navigation.on('toggled', function (event, options) {
    if(options.state == true) {
      $header.addClass('is-deployed');
      is_deployed = true;
    } else {
      $header.removeClass('is-deployed');
      is_deployed = false;
      update();
    }
  });

  if($button.length > 0) {
    var $target = $($button.data('target'));
    var is_displayed = false;
    var observer;

    var handle_intersect = function(entries, observer) {
      at_least_one_in_viewport = false;

      entries.forEach(function(entry, index) {
        if(entry.intersectionRatio > 0 && is_displayed) {
          $button.removeClass('is-displayed');
          is_displayed = false;
        } else if (entry.intersectionRatio == 0 && !is_displayed) {
          $button.addClass('is-displayed');
          is_displayed = true;
        }
      });
    };

    var create_observer = function () {
      var options = {
        root: null,
        rootMargin: "0px",
        threshold: build_threshold(20)
      };

      observer = new IntersectionObserver(handle_intersect, options);
    };

    create_observer();
    observer.observe($target[0]);
  }

  $window.on('scroll', function () {
    has_scroll =  true;
  });

  $window.on('resize', $.throttle(function () {
    header_height = $header.outerHeight();
  }));

  update();
});
