$(function() {
  $promotions = $('.js-promotions');

  // Early exit
  if(!$promotions.length) return;

  $promotions.each(function (index, promotion) {
    var $promotion = $(promotion);
    var $carousel = $promotion.find('.js-promotions-carousel');

    $carousel.slick({
      adaptiveHeight: false,
      prevArrow: $promotion.find('.js-promotions-prev'),
      nextArrow: $promotion.find('.js-promotions-next'),
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            arrows: false,
          }
        },
        {
          breakpoint: 780,
          settings: {
            arrows: false,
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 620,
          settings: {
            arrows: false,
            slidesToShow: 1,
          }
        }
      ]
    });
  });
});