$(function () {
  var $targets = $('.js-scroll');

  if(!$targets.length) return;

  var $window = $(window);
  var $document = $(document);
  var has_scroll = false;
  var has_resize = false;
  var observer;
  var at_least_one_in_viewport = false;

  var handle_intersect = function(entries, observer) {
    at_least_one_in_viewport = false;

    entries.forEach(function(entry, index) {
      if(entry.intersectionRatio > 0) {
        at_least_one_in_viewport = true;
      }
    });
  };

  var create_observer = function () {
    var options = {
      root: null,
      rootMargin: "0px",
      threshold: build_threshold(10)
    };

    observer = new IntersectionObserver(handle_intersect, options);
  };

  var scroll = function () {
    if((has_resize || has_scroll) && at_least_one_in_viewport) {
      //document.documentElement.style.setProperty('--scroll', Math.round($document.scrollTop()));
      document.documentElement.style.setProperty('--scroll-container', Math.round($document.scrollTop() / 10) + "deg");
      document.documentElement.style.setProperty('--scroll-container-counterclockwise', Math.round($document.scrollTop() * -1 / 10) + "deg");
      document.documentElement.style.setProperty('--scroll-shape', Math.round($document.scrollTop() / 60) + "deg");
    }

    has_scroll = false;
    has_resize = false;
    window.requestAnimationFrame(scroll);
  };

  create_observer();

  $targets.each(function(index, target) {
    observer.observe(target);
  });

  $window.on('scroll', function() {
    has_scroll = true;
  });

  $window.on('resize', function() {
    has_resize = true;
  });

  scroll();
});
