$(function() {
  $testimonials = $('.js-testimonials');

  // Early exit
  if(!$testimonials.length) return;

  $testimonials.each(function (index, testimonial) {
    var $testimonial = $(testimonial);
    var $carousel = $testimonial.find('.js-testimonials-carousel');

    $carousel.slick({
      adaptiveHeight: true,
      prevArrow: $testimonial.find('.js-testimonials-prev'),
      nextArrow: $testimonial.find('.js-testimonials-next'),
    });
  });
});
