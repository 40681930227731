$(function () {
  var $footer = $("#footer");
  var $newsletter = $footer.find('#newsletter');
  var $container = $newsletter.find('.js-form-container');
  var $fields = $newsletter.find('.js-form-fields');
  var $submit = $newsletter.find('button[type="submit"]');
  var ajax = false;

  $newsletter.on('submit', function (event) {
    event.preventDefault();

    if(!ajax) {
      $.ajax({
        type: $newsletter.attr("method"),
        url: $newsletter.attr("action"),
        data: $newsletter.serialize() + "&" + $submit.attr('name') + "=" + $submit.attr('value'),
        dataType: "json",
        beforeSend: function(jqXHR, settings) {
          ajax = true;
          $newsletter.find('.is-invalid').removeClass('is-invalid');
          $newsletter.find('.js-form-feedback').remove();
        },
        success: function(response, textStatus, jqXHR) {
          if(response.success) {
            $container.html('<div class="s-richtext"><p class="c-footer__title">' + response.data.message + '</p></div>');
          } else {
            if (response.data.errors) {
              var fields = Object.keys(response.data.errors);
              for(var i = 0; i < fields.length; i++) {
                $newsletter.find('[name*="' + fields[i] + '"]').addClass('is-invalid').closest('.js-form-field').append('<p class="c-form__feedback js-form-feedback u-color-error">' + response.data.message + '</p>');
              }
            } else {
              $fields.append('<p class="c-form__feedback js-form-feedback u-color-error">' + response.data.message + '</p>');
            }
          }
        },
        error: function(jqXHR, textStatus, errorThrown) {
          alert(errorThrown);
        },
        complete: function(jqXHR, textStatus) {
          ajax = false;
        }
      });
    }
  });
});
