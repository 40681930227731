$(function () {
  tabMapping = {
    "sell_nav": "sell_tab",
    "repair_nav": "repair_tab",
    "resell_nav": "resell_tab",
  };
  $navs = $('.c-tab__head-item');
  $tabs = $('.c-tab__content');
  $navs.click(function() {
    $navs.removeClass('is-active');
    $(this).addClass('is-active');
    $tabs.hide();
    tabId = tabMapping[$(this).attr('id')];
    $('#' + tabId).css("display", "flex").hide().fadeIn(600);
  });
});