$(function() {
  $slideshows = $('.js-slideshow');

  // Early exit
  if(!$slideshows.length) return;

  $slideshows.each(function (index, slideshow) {
    var $slideshow = $(slideshow);
    var $images = $slideshow.find('.js-slideshow-images');
    var $content = $slideshow.find('.js-slideshow-content');

    $images.slick({
      prevArrow: $slideshow.find('.js-slideshow-prev'),
      nextArrow: $slideshow.find('.js-slideshow-next'),
      asNavFor: $content,
    });

    $content.slick({
      adaptiveHeight: true,
      arrows: false,
      asNavFor: $images,
    });
  });
});
